
  import { ref, computed, defineComponent, onMounted } from 'vue';
  import { getIllustrationsPath } from '@/core/helpers/assets';
  import { useStore } from 'vuex';
  import { Actions } from '@/store/enums/StoreEnums';

  export default defineComponent({
    name: 'notifications-menu',
    components: {},
    setup() {
      const store = useStore();

      onMounted(async () => {
        await store.dispatch(Actions.GET_NOTIFICATIONS);
      });
      const notificationCount = computed(() => store.getters.notificationCount);
      const notificationList = computed(() => store.getters.notificationList);

      let hasMoreData = true;
      let isLoading = ref(false);
      let page = 1;

      const handleScroll = async (event) => {
        console.log(notificationCount.value, notificationList.value.length);
        if (notificationCount.value <= notificationList.value.length) {
          hasMoreData = false;
          return;
        }
        const target = event.target;
        const threshold = 100;
        const position = target.scrollTop + target.offsetHeight;
        const height = target.scrollHeight;
        if (position >= height - threshold && hasMoreData && !isLoading.value) {
          isLoading.value = true;
          await store.dispatch(
            Actions.UPDATE_NOTIFICATIONS_CURRENT_PAGE,
            ++page
          );
          await store.dispatch(Actions.GET_NOTIFICATIONS);
          target.scrollTop = target.scrollTop - 100;
          isLoading.value = false;
        } else {
          console.log('Scroll event triggered', event);
        }
      };
      return {
        notificationList,
        getIllustrationsPath,
        handleScroll,
        isLoading,
      };
    },
  });
