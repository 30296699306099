const DocMenuConfig = [
  {
    heading: 'Manage',
    route: '/manage',
    pages: [
      {
        sectionTitle: 'Members',
        route: '/member',
        svgIcon: '/media/icons/duotune/technology/teh004.svg',
        fontIcon: 'bi-sticky',
        ability: {
          method: 'menu',
          resource: ['roles-permissions'],
        },
        sub: [
          {
            heading: 'Roles & Permission',
            route: '/manage/members/groups-listing',
            ability: { method: 'menu', resource: 'roles-permissions' },
          },
          {
            heading: 'Admins',
            route: '/manage/members/admins-listing',
            ability: { method: 'menu', resource: 'admins' },
          },
          {
            heading: 'Tickets',
            route: '/manage/operations/tickets/ticket-listing',
            ability: { method: 'menu', resource: 'tickets' },
          },
        ],
      },
      {
        sectionTitle: 'Settings',
        route: '/settings',
        svgIcon: '/media/icons/duotune/coding/cod001.svg',
        fontIcon: 'bi-sticky',
        ability: {
          method: 'menu',
          resource: ['settings'],
        },
        sub: [
          {
            heading: 'Guardians',
            route: '/manage/setting/lookups/guardian-type/guardians-listing',
            ability: { method: 'menu', resource: 'settings' },
          },
          {
            heading: 'Genders',
            route: '/manage/setting/lookups/genders/genders-listing',
            ability: { method: 'menu', resource: 'settings' },
          },
          {
            heading: 'Horse Gender',
            route: '/manage/setting/lookups/horse-gender/horse-gender-listing',
            ability: { method: 'menu', resource: 'settings' },
          },
          {
            heading: 'Horse Breed',
            route: '/manage/setting/lookups/horse-breed/horse-breed-listing',
            ability: { method: 'menu', resource: 'settings' },
          },
          {
            heading: 'Horse Color',
            route: '/manage/setting/lookups/horse-color/horse-color-listing',
            ability: { method: 'menu', resource: 'settings' },
          },
          {
            heading: 'Horse Category',
            route:
              '/manage/setting/lookups/horse-category/horse-category-listing',
            ability: { method: 'menu', resource: 'settings' },
          },
          {
            heading: 'Horse Type',
            route: '/manage/setting/lookups/horse-type/horse-type-listing',
            ability: { method: 'menu', resource: 'settings' },
            hide: true,
          },
          {
            heading: 'Leave Type',
            route: '/manage/setting/lookups/leave-type/leave-type-listing',
            ability: { method: 'menu', resource: 'settings' },
          },
          {
            heading: 'Cancel Reason',
            route:
              '/manage/setting/lookups/cancel-reason/cancel-reason-listing',
            ability: { method: 'menu', resource: 'settings' },
          },
          {
            heading: 'Nationality',
            route: '/manage/setting/lookups/nationality/nationality-listing',
            ability: { method: 'menu', resource: 'settings' },
          },
          {
            heading: 'Passports',
            route: '/manage/setting/lookups/passport-type/passports-listing',
            ability: { method: 'menu', resource: 'settings' },
          },
          {
            heading: 'Payment Method',
            route:
              '/manage/setting/lookups/payment-method/payment-methods-listing',
            ability: { method: 'menu', resource: 'settings' },
          },
          {
            heading: 'Documents',
            route: '/manage/setting/lookups/document-type/documents-listing',
            ability: { method: 'menu', resource: 'settings' },
          },
          {
            heading: 'Treatments',
            route: '/manage/setting/lookups/treatment-type/treatments-listing',
            ability: { method: 'menu', resource: 'settings' },
          },
          {
            heading: 'Statuses',
            route: '/manage/setting/lookups/statuses/statuses-listing',
            ability: { method: 'menu', resource: 'settings' },
            hide: true,
          },
          {
            heading: 'Course Status',
            route:
              '/manage/setting/lookups/course-status/course-status-listing',
            ability: { method: 'menu', resource: 'settings' },
          },
          {
            heading: 'Services',
            route: '/manage/setting/service-listing',
            ability: { method: 'menu', resource: 'settings' },
          },
          {
            heading: 'Services Type',
            route: '/manage/setting/lookups/service-type/services-listing',
            ability: { method: 'menu', resource: 'settings' },
          },
          {
            heading: 'Classes',
            route: '/manage/setting/lookups/class-type/classes-listing',
            ability: { method: 'menu', resource: 'settings' },
          },
          {
            heading: 'Job Title',
            route: '/manage/setting/lookups/job-title/job-title-listing',
            ability: { method: 'menu', resource: 'settings' },
          },
          {
            heading: 'Horse Location',
            route: '/manage/setting/horse-location/horse-location-listing',
            ability: { method: 'menu', resource: 'settings' },
          },
          {
            heading: 'Holiday Type',
            route: '/manage/setting/holiday-type/holidays-listing',
            ability: { method: 'menu', resource: 'settings' },
          },
          {
            heading: 'Class Level',
            route: '/manage/setting/class-level/class-level-listing',
            ability: { method: 'menu', resource: 'settings' },
          },
          {
            heading: 'Class Category',
            route: '/manage/setting/class-category/class-category-listing',
            ability: { method: 'menu', resource: 'settings' },
          },
          {
            heading: 'Customer Age Group',
            route: '/manage/setting/customer-age/customer-age-listing',
            ability: { method: 'menu', resource: 'settings' },
          },
          {
            heading: 'About Us',
            route: '/manage/setting/about-us-listing',
            ability: { method: 'menu', resource: 'settings' },
          },
          {
            heading: 'Terms of Use',
            route: '/manage/setting/terms-of-use-listing',
            ability: { method: 'menu', resource: 'settings' },
          },
          {
            heading: 'Privacy Policy',
            route: '/manage/setting/privacy-listing',
            ability: { method: 'menu', resource: 'settings' },
          },
          {
            heading: 'Rules & Regulations',
            route: '/manage/setting/rules-regulations-listing',
            ability: { method: 'menu', resource: 'settings' },
          },
          {
            heading: 'Complaints',
            route: '/manage/setting/complaints-listing',
            ability: { method: 'menu', resource: 'settings' },
          },
          {
            heading: 'Announcements',
            route: '/manage/setting/announcement-listing',
            ability: { method: 'menu', resource: 'settings' },
          },
        ],
      },
      {
        heading: 'Dashboard',
        route: '/manage/trainer/dashboard-trainer',
        svgIcon: '/media/icons/duotune/layouts/lay001.svg',
        ability: { method: 'menu', resource: 'trainer-dashboard' },
      },
      {
        heading: 'Weekly Lessons',
        route: '/manage/trainer/weekly-lessons',
        svgIcon: '/media/icons/duotune/communication/com008.svg',

        ability: { method: 'menu', resource: 'trainer-weekly-lesson' },
      },
      {
        heading: 'Attendance Lesson',
        route: '/manage/trainer/attendence',
        svgIcon: '/media/icons/duotune/communication/com008.svg',
        ability: { method: 'menu', resource: 'trainer-weekly-lesson' },
      },
      {
        heading: 'Change Password',
        route: '/manage/trainer/change-password',
        svgIcon: '/media/icons/duotune/coding/cod009.svg',
        ability: { method: 'menu', resource: 'trainer-profile' },
      },
      {
        heading: 'Profile',
        route: '/manage/members/profile',
        svgIcon: '/media/icons/duotune/coding/cod009.svg',
        ability: { method: 'menu', resource: 'trainer-profile' },
      },
      {
        sectionTitle: 'Operations',
        route: '/Operations',
        svgIcon: '/media/icons/duotune/technology/teh004.svg',
        fontIcon: 'bi-sticky',
        ability: {
          method: 'menu',
          resource: ['groomer', 'trainer', 'arena', 'lesson'],
        },
        sub: [
          {
            heading: 'Groomers',
            route: '/operations/groomers/groomers-listing',
            ability: { method: 'menu', resource: 'groomer' },
            resource: ['trainer', 'arena'],
          },
          {
            heading: 'Trainers',
            route: '/manage/operations/trainers/trainers-listing',
            ability: { method: 'menu', resource: 'trainer' },
          },
          {
            heading: 'Cost Matrix',
            route: '/manage/operations/coost-matrix',
            ability: { method: 'menu', resource: 'trainer' },
          },
          {
            heading: 'Cost Matrix V2',
            route: '/manage/operations/cost-matrix-v2',
            ability: { method: 'menu', resource: 'trainer' },
          },
          {
            heading: 'Arenas',
            route: '/manage/operations/arenas/arenas-listing',
            ability: { method: 'menu', resource: 'arena' },
          },
          {
            heading: 'Customers',
            route: '/manage/operations/customers/customers-listing',
            ability: { method: 'menu', resource: 'arena' },
          },
          {
            heading: 'Horses',
            route: '/manage/operations/horses/horses-listing',
            ability: { method: 'menu', resource: 'arena' },
          },
          {
            heading: 'Lessons',
            route: '/manage/operations/lessons/lessons-listing',
            ability: { method: 'menu', resource: 'lesson' },
          },
          {
            heading: 'Lessons Pending',
            route: '/manage/operations/lessons/lessons-pending-listing',
            ability: { method: 'menu', resource: 'lesson' },
          },

          {
            heading: 'Staff',
            route: '/manage/operations/staff/staff-listing',
            ability: { method: 'menu', resource: 'staff' },
          },
          {
            heading: 'cancellation Request',
            route: '/manage/operations/cancellation/cancellation-listing',
            ability: { method: 'menu', resource: 'staff' }, // should change the resource
          },
        ],
      },
      {
        sectionTitle: 'Finance',
        route: '/settings',
        svgIcon: '/media/icons/duotune/finance/fin010.svg',
        fontIcon: 'bi-sticky',
        ability: {
          method: 'menu',
          resource: ['finance'],
        },
        sub: [
          {
            heading: 'Invoice',
            route: '/manage/finance/invoice-listing',
            ability: { method: 'menu', resource: 'finance' },
          },
          {
            heading: 'Customer Credit',
            route: '/manage/finance/customer-credit-listing',
            ability: { method: 'menu', resource: 'finance' },
          },
          {
            heading: 'Customer Service Payment',
            route: '/manage/finance/customer-service-statment-listing',
            ability: { method: 'menu', resource: 'finance' },
          },
          {
            heading: 'Customer Balance Statement',
            route: '/manage/finance/customer-balance-statment-listing',
            ability: { method: 'menu', resource: 'finance' },
          },
        ],
      },
      {
        sectionTitle: 'Reports',
        route: '/reports',
        svgIcon: '/media/icons/duotune/files/fil002.svg',
        fontIcon: 'bi-sticky',
        ability: {
          method: 'menu',
          resource: ['report'],
        },
        sub: [
          {
            heading: 'Customers Report',
            route: '/manage/reports/customer-attendance-report',
            ability: { method: 'menu', resource: 'report' },
          },
          {
            heading: 'Daily Schedule Report For Horses',
            route: '/manage/reports/horses-report',
            ability: { method: 'menu', resource: 'report' },
          },

          {
            heading: 'Trainer Lesson Summary',
            route: '/manage/reports/trainer-lesson-summary-report',
            ability: { method: 'menu', resource: 'report' },
          },
          {
            heading: 'Daily Schedule Report For Trainers',
            route: '/manage/reports/lessons-report',
            ability: { method: 'menu', resource: 'report' },
          },
          {
            heading: 'Daily Schedule Report For Customers',
            route: '/manage/reports/customer-lessons-report',
            ability: { method: 'menu', resource: 'report' },
          },
          {
            heading: 'Attendance Report GrpByDate',
            route: '/manage/reports/lessons-type-report',
            ability: { method: 'menu', resource: 'report' },
          },
          {
            heading: 'Horse Count',
            route: '/manage/reports/horse-lessons-report',
            ability: { method: 'menu', resource: 'report' },
          },
          {
            heading: 'Horses Utilization Report',
            route: '/manage/reports/horse-lessons-count-report',
            ability: { method: 'menu', resource: 'report' },
          },
          {
            heading: 'Groom Utilization Report ',
            route: '/manage/reports/groomer-lessons-count-report',
            ability: { method: 'menu', resource: 'report' },
          },
          {
            heading: 'Customers Balance Report',
            route: '/manage/reports/customer-balance-report',
            ability: { method: 'menu', resource: 'report' },
          },
          {
            heading: 'All Customer Account',
            route: '/manage/reports/all-customer-account-report',
            ability: { method: 'menu', resource: 'report' },
          },
          {
            heading: 'All Customer Expired Lesson',
            route: '/manage/reports/customer-payment-report',
            ability: { method: 'menu', resource: 'report' },
          },
          {
            heading: 'Daily Statement Report',
            route: '/manage/reports/customer-account-report',
            ability: { method: 'menu', resource: 'report' },
          },
          {
            heading: 'Customer Account Statement',
            route: '/manage/reports/customer-statement-report',
            ability: { method: 'menu', resource: 'report' },
          },
          {
            heading: 'Horse Timeline Report - Day',
            route: '/manage/reports/horse-daily-lesson-day-report',
            ability: { method: 'menu', resource: 'report' },
          },
          {
            heading: 'Horse Timeline Report - Night',
            route: '/manage/reports/horse-daily-lesson-night-report',
            ability: { method: 'menu', resource: 'report' },
          },
          {
            heading: 'Groom Timeline Report - Day',
            route: '/manage/reports/groom-daily-lesson-day-report',
            ability: { method: 'menu', resource: 'report' },
          },
          {
            heading: 'Groom Timeline Report - Night',
            route: '/manage/reports/groom-daily-lesson-night-report',
            ability: { method: 'menu', resource: 'report' },
          },
          {
            heading: 'Arena Timeline Report - Day',
            route: '/manage/reports/arena-daily-lesson-day-report',
            ability: { method: 'menu', resource: 'report' },
          },
          {
            heading: 'Arena Timeline Report - Night',
            route: '/manage/reports/arena-daily-lesson-night-report',
            ability: { method: 'menu', resource: 'report' },
          },
          {
            heading: 'Treatment Overview',
            route: '/manage/reports/treatment-overview-report',
            ability: { method: 'menu', resource: 'report' },
          },
          {
            heading: 'Horse Tag',
            route: '/manage/reports/horse-tag-report',
            ability: { method: 'menu', resource: 'report' },
          },
        ],
      },
      {
        sectionTitle: 'Shop',
        route: '/Operations',
        svgIcon: '/media/icons/duotune/technology/teh004.svg',
        fontIcon: 'bi-sticky',
        ability: {
          method: 'menu',
          resource: ['shop'],
        },
        sub: [
          {
            heading: 'Shop',
            route: '/manage/operations/shop/shop-listing',
            ability: { method: 'menu', resource: 'shop' },
          },
          {
            heading: 'Category',
            route: '/manage/operations/category/category-listing',
            ability: { method: 'menu', resource: 'shop' },
          },
          {
            heading: 'Product',
            route: '/manage/operations/product/product-listing',
            ability: { method: 'menu', resource: 'shop' },
          },
          {
            heading: 'Offer',
            route: '/manage/operations/offer/offer-listing',
            ability: { method: 'menu', resource: 'offer' },
          },
        ],
      },
    ],
  },
];

export default DocMenuConfig;
